<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <LoadingOverlay :loading="loading" />
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from "@vue/composition-api";
// eslint-disable-next-line import/no-unresolved
import useAppConfig from "@core/@app-config/useAppConfig";
import { useRouter } from "@core/utils";
import { useLayout } from "@core/layouts/composable/useLayout";

// Layouts
import LayoutContentVerticalNav from "@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";
import LayoutContentHorizontalNav from "@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue";
import LayoutBlank from "@/layouts/variants/blank/LayoutBlank.vue";

// Dynamic vh
import useDynamicVh from "@core/utils/useDynamicVh";

import LoadingOverlay from "@/components/LoadingOverlay";

import { mapActions, mapState } from "vuex";

import { setVeeLocale } from "@/utils";

import CancelTripModal from "@/components/CancelTripModal.vue";
import CancelTripModalEN from "@/components/CancelTripModalEN.vue";

import DevicePixelRatio from "@/utils/DevicePixelRatio";

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LoadingOverlay,
  },
  setup() {
    const { route } = useRouter();
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } =
      useAppConfig();

    const { handleBreakpointLayoutSwitch } = useLayout();
    handleBreakpointLayoutSwitch();

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === "blank") return "layout-blank";
      if (route.value.meta.layout === "content")
        return `layout-content-${appContentLayoutNav.value}-nav`;

      return null;
    });

    useDynamicVh();

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", ["_id", "isLogged"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title =
          `${this.$t(to.meta.title)} | ${this.$t("Ridery Corporate")}` ||
          "Ridery Corporativo";
      },
    },
    "$vuetify.lang.current": {
      immediate: true,
      handler(to, from) {
        document.title =
          `${this.$t(this.$route.meta.title)} | ${this.$t(
            "Ridery Corporate"
          )}` || "Ridery Corporativo";
      },
    },
  },
  methods: {
    async toggleCancelTripModal(data) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "30%",
        transition: "dialog-top-transition",
        data: data,
        hideCancel: true,
        persistent: true,
      };

      if (
        this.$vuetify.lang.current === "es" &&
        this.$route.name !== "TripInfo"
      ) {
        const dialogInstance = await this.$dialog.showAndWait(
          CancelTripModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        }
      }
      if (this.$route.name !== "TripInfo") {
        const dialogInstance = await this.$dialog.showAndWait(
          CancelTripModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        }
      }
    },
  },
  sockets: {
    connect: function () {
      console.info("socket connected");
    },
    CANCEL_TRIP_CORPORATE(data) {
      let corp_id = data.corporate_id;
      if (this.isLogged && corp_id === this._id) {
        this.toggleCancelTripModal(data);
        console.info(data);
      }
    },
  },
  beforeMount() {
    setVeeLocale(this.$i18n.locale);
  },
  created() {
    // new DevicePixelRatio().init()
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  /* background-color: #d8d8d8; */
}
::-webkit-scrollbar-thumb:active {
  /* background-color: #d8d8d8; */
}
::-webkit-scrollbar-corner {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
