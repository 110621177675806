export default {
  user: null,
  isLogged: false,
  token: null,
  _id: null,
  unique_id: null,
  email: null,
  name: null, 
  countryName: null,
  phone: null, 
  countryPhoneCode: null,
  wallet: null,
  overdraft: null,
  picture_url: null,
  pre_time: null,
  lang: 'es',
  admin_phone: "+584128835418",
  admin_email: "viajes@ridery.app",
  is_active_shipments: null,
  is_active_multi_stops: null,
  is_corporate_master: null,
  is_used_google_maps_service: null,
}
