<template>
  <div v-if="$route.name !== 'users'">
    <v-fade-transition mode="out-in">
      <v-avatar
        color="grey lighten-2"
        size="36"
        @click="handleLocale()"
        class="mySwitcher"
      >
        {{ locales.find((l) => l.locale === $i18n.locale).title }}
      </v-avatar>
    </v-fade-transition>
  </div>
</template>

<script>
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";
import useAppConfig from "@core/@app-config/useAppConfig";
import { mapActions, mapState } from "vuex";


export default {
  setup() {
    const { isRtl } = useAppConfig();

    const locales = [
      {
        title: "ES",
        locale: "es",
      },
      {
        title: "EN",
        locale: "en",
      },
    ];

    const updateActiveLocale = (locale) => {
      // Set to RTL mode if locale is arabic
      isRtl.value = locale === "ar";

      loadLanguageAsync(locale);
    };

    return {
      locales,
      updateActiveLocale,
    };
  },
  methods: {
    ...mapActions("auth", ["setLang"]),
    handleLocale() {
      if (this.$route.name !== "users") {
        let prevLocal = this.$i18n.locale;
        let locale = "";
        switch (prevLocal) {
          case "es":
            locale = "en";
            break;
          case "en":
            locale = "es";
            break;
          default:
            break;
        }
        this.setLang(locale);
        setVeeLocale(locale);
        this.$vuetify.lang.current = locale;
        loadLanguageAsync(locale);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mySwitcher:hover {
  cursor: pointer;
}
</style>
